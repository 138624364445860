
// Import the core angular services.
import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";

// ----------------------------------------------------------------------------------- //
// ----------------------------------------------------------------------------------- //

@Component({
    selector: "rain-fall",
    inputs: ["depth", "speed"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ["./rain-fall.component.scss"],
    template:
        `
		<div
			[style.animation-duration.s]="verticalDuration"
			[style.animation-delay.s]="verticalDelay"
			class="vertical-track">
			<div
				[style.animation-duration.s]="horizontalDuration"
				[style.animation-delay.s]="horizontalDelay"
				class="horizontal-track">
				<span
					[style.opacity]="flakeOpacity"
					[style.width.px]="flakeSize"
					[style.height.px]="flakeSize"
					class="flake">
				</span>
			</div>
		</div>
	`
})
export class RainFallComponent {

    public depth: number;
    public speed: number;

    public flakeOpacity: number;
    public flakeSize: number;
    public horizontalDuration: number;
    public horizontalDelay: number;
    public verticalDelay: number;
    public verticalDuration: number;

    // I initialize the snow-flake component.
    constructor() {

        this.depth = 2;
        this.speed = 1;

        this.flakeOpacity = 1;
        this.flakeSize = 1;
        this.verticalDuration = 5;
        this.verticalDelay = 0;
        this.horizontalDuration = 3;
        this.horizontalDelay = 0;

    }

    // ---
    // PUBLIC MEHTODS.
    // ---

    // I get called whenever the input bindings change.
    public ngOnChanges(): void {

        switch (this.speed) {
            case 1:
                this.verticalDuration = 0.3;
                this.horizontalDuration = 3;
                break;
            case 2:
                this.verticalDuration = 0.5;
                this.horizontalDuration = 3;
                break;
            case 3:
                this.verticalDuration = 0.8;
                this.horizontalDuration = 3.5;
                break;
            case 4:
                this.verticalDuration = 1;
                this.horizontalDuration = 4;
                break;
            case 5:
                this.verticalDuration = 1.3;
                this.horizontalDuration = 5;
                break;
                case 6:
                this.verticalDuration = 1.6;
                this.horizontalDuration = 5.5;
                break;
                case 7:
                this.verticalDuration = 1.9;
                this.horizontalDuration = 6;
                break;
                case 8:
                this.verticalDuration = 2;
                this.horizontalDuration = 6.5;
                break;
                case 9:
                this.verticalDuration = 2.3;
                this.horizontalDuration = 7;
                break;
                case 10:
                this.verticalDuration = 2.6;
                this.horizontalDuration = 7.5;
                break;
        }

        // Choose a random offset for the animation so that we fill the screen with snow
        // flakes rather than having them all start together at the top.
        this.verticalDelay = (Math.random() * -this.verticalDuration);
        this.horizontalDelay = (Math.random() * -this.horizontalDuration);

        switch (this.depth) {
            case 1:
                this.flakeSize = 1;
                this.flakeOpacity = 1;
                break;
            case 2:
                this.flakeSize = 2;
                this.flakeOpacity = 1;
                break;
            case 3:
                this.flakeSize = 3;
                this.flakeOpacity = 0.9;
                break;
            case 4:
                this.flakeSize = 5;
                this.flakeOpacity = 0.5;
                break;
            case 5:
                this.flakeSize = 10;
                this.flakeOpacity = 0.2;
                break;

            case 6:
                this.flakeSize = 6;
                this.flakeOpacity = 0.6;
            break;
            case 7:
                this.flakeSize = 7;
                this.flakeOpacity = 0.7;
            break;
            case 8:
                this.flakeSize = 10;
                this.flakeOpacity = 0.2;
            break;
            case 9:
                this.flakeSize = 11;
                this.flakeOpacity = 0.9;
            break;
            case 10:
                this.flakeSize = 10;
                this.flakeOpacity = 0.3;
            break;
        }

    }

}