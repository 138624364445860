import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";


@Component({
    selector: 'chat-popup-admin',
    templateUrl: 'chat-popup-admin.component.html',
    styleUrls: ['./chat-popup-admin.component.scss']

})
export class ChatAdminDialog implements OnInit {
    public postForm: FormGroup;
    public submitting: boolean = false;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<ChatAdminDialog>
    ) {

    }

    ngOnInit() {
        // alert("In Chat Admin");
    }
    

}