import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountSandbox } from '../../../../../core/account/account.sandbox';
import { environment } from '../../../../../environments/environment';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ListsSandbox } from '../../../../../core/lists/lists.sandbox';
import { AccountService } from '../../../../../core/account/account.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
	selector: 'app-vendor-review',
	templateUrl: './vendor-review.component.html',
	styleUrls: ['./vendor-review.component.scss']
})
export class VendorReviewComponent implements OnInit, OnDestroy {
	@Input('vendorId') vendorId: number;
	public orderProductId: any;
	public imageUrl = environment.imageUrl;
	public details: any;
	private subscription: Array<Subscription> = [];
	public currentRate = 0;
	public textError = false;
	public ratingError = false;
	public submitted = false;
	public review = '';
	public ratingArr = [];
	public starCount = 5;
	public rating = 0;
	public color = 'accent';

	constructor(public route: ActivatedRoute,
		public sandbox: AccountService,
		public router: Router,
		public snackBar: MatSnackBar,
		public listSandbox: ListsSandbox) {

	}

	ngOnInit() {
		for (let index = 0; index < this.starCount; index++) {
			this.ratingArr.push(index);
		}
	}

	submit(text) {
		this.submitted = true;
		this.textError = false;
		this.ratingError = false;

		if (text && this.rating > 0) {
			const params: any = {};
			params.vendorId = this.vendorId;
			params.review = text;
			params.rating1 = this.rating;
			this.sandbox.addVendorReview(params).toPromise().then((response) => {
				this.snackBar.open('Review added successfully', '×', {
					panelClass: 'success',
					verticalPosition: 'top',
					horizontalPosition: 'right',
					duration: 3000
				});
			});
		} else {
			if (!text) {
				this.textError = true;
			}
			if (this.rating === 0) {
				this.ratingError = true;
			}
		}
	}


	showIcon(index: number) {
		if (this.rating >= index + 1) {
			return 'star';
		} else {
			return 'star_border';
		}
	}

	onClick(rating: number) {
		this.rating = rating;
		return false;
	}

	ngOnDestroy() {
		this.subscription.forEach(each => each.unsubscribe());
	}

}
