import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";


@Component({
    selector: 'chat-popup',
    templateUrl: 'chat-popup.component.html',
    styleUrls: ['./chat-popup.component.scss']

})
export class ChatDialog implements OnInit {
    public postForm: FormGroup;
    public submitting: boolean = false;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<ChatDialog>
    ) {

    }

    ngOnInit() {
       
    }
    

}