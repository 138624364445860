import { isPlatformBrowser } from "@angular/common";
import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PostService } from "../../../../core/posts/post.service";
import { VendorEmailShareDialog } from "./vendor-email-share/vendor-email-share.component";
import { VendorShareDialog } from "./vendor-share-popup/vendor-share-popup.component";


@Component({
    selector: 'vendor-share',
    templateUrl: './vendor-share.component.html',
    styleUrls: ['./vendor-share.component.scss']
})

export class VendorShareComponent implements OnInit, OnDestroy {

    public dialogRef: any;
    @Input() public vendorId: number;
    constructor(
        private postService: PostService,
        private dialog: MatDialog,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {

    }


    ngOnInit() {

    }

    openDialog() {
        this.dialogRef = this.dialog.open(VendorShareDialog, {
            data: {
                title: "Share Vendor",
                vendorId: this.vendorId
            }
        });
        this.dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result}`);
        });
    }

    
    openEmailShareDialog() {
        this.dialogRef = this.dialog.open(VendorEmailShareDialog, {
            data: {
                title: "Share Vendor",
                vendorId: this.vendorId
            }
        });
        this.dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result}`);
        });
    }

    public share(socialShare: string) {
        if (isPlatformBrowser(this.platformId)) {
            const url = encodeURI(window.location.href);
            switch (socialShare) {
                case 'fb':
                    window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`);
                    break;
                case 'tw':
                    window.open(`https://twitter.com/home?status=${url}`);
                    break;
                case 'in':
                    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${url}`);
                    break;
                case 'tr':
                    window.open(`https://www.tumblr.com/widgets/share/tool?canonicalUrl=${url}`);
                    break;
                case 'dg':
                    window.open(`http://digg.com/submit?url=${url}`);
                    break;
                case 'rd':
                    window.open(`https://reddit.com/submit?url=${url}`);
                    break;
                case 'pn':
                    window.open(`https://www.pinterest.com/pin/find/?url=${url}`);
                    break;
                default:
                    break;
            }
        }
    }

    ngOnDestroy() {

    }
}