import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Renderer2, RendererFactory2, ViewEncapsulation } from "@angular/core";

@Injectable()
export class LinkCanonicalService {
    private renderer: Renderer2;

    constructor(
        private rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) private document
    ) {
        this.renderer = this.rendererFactory.createRenderer(this.document, {
            id: '-1',
            encapsulation: ViewEncapsulation.None,
            styles: [],
            data: {}
        });
    }

    setCanonicalTag(canonicalUrl: string | undefined): void {
        try {
            const head = this.document.head;
            const existingTag = this.existingTag();
            if (existingTag) {
                this.renderer.removeChild(head, existingTag);
            }

            if (canonicalUrl) {
                const link = this.renderer.createElement('link');
                this.renderer.setAttribute(link, 'rel', 'canonical');
                this.renderer.setAttribute(link, 'href', canonicalUrl);
                head.appendChild(link);
            }
        } catch (e) {
            console.error('Error rendering the canonical link tag');
        }
    }

    private existingTag(): any {
        const selector = `link[rel="canonical"]`;
        return this.document.querySelector(selector);
    }
}