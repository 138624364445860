import { isPlatformBrowser } from "@angular/common";
import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PostService } from "../../../../../core/posts/post.service";

@Component({
    selector: 'vendor-email-chat-share',
    templateUrl: 'vendor-email-chat-share.component.html',
    styleUrls: ['./vendor-email-chat-share.component.scss']
})
export class VendorEmailChatShareDialog implements OnInit {
    public postForm: FormGroup;
    public submitting: boolean = false;
    constructor(
        private postService: PostService,
        private formBuilder: FormBuilder,
        @Inject(PLATFORM_ID) private platformId: Object,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<VendorEmailChatShareDialog>
    ) {

    }

    ngOnInit() {
        this.postForm = this.formBuilder.group({
            comment: ['', Validators.required]
        });

        // if (isPlatformBrowser(this.platformId)) {
        //     this.postForm.controls['comment'].setValue(encodeURI(window.location.href));
        // }

    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    submit() {

        if (!this.postForm.valid) {
            this.validateAllFormFields(this.postForm);
            return;
        }
        this.submitting = true;
        this.postService.shareChatUrl({
            email: this.data.email,
            vendorId: this.data.vendorId,
            customerEmail: this.data.customer.email,
            comment: this.postForm.controls['comment'].value.replace(/\n/g, '<br/>')
        }).toPromise().then((response) => {
            this.dialogRef.close();
        }, (err) => {

        })
    }


}