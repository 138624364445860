import { Component, Input, OnDestroy, OnInit, PLATFORM_ID, Inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PostService } from "../../../../core/posts/post.service";
import { ProductShareDialog } from "./product-share-popup/product-share-popup.component";
import { VendorEmailShareDialog } from "../../vendor-detail/vendor-share/vendor-email-share/vendor-email-share.component";
import { isPlatformBrowser } from "@angular/common";


@Component({
    selector: 'product-share',
    templateUrl: './product-share.component.html',
    styleUrls: ['./product-share.component.scss']
})

export class ProductShareComponent implements OnInit, OnDestroy {

    public dialogRef: any;
    @Input() public productId: number;
    @Input() public product: any;
    constructor(
        private postService: PostService,
        private dialog: MatDialog,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {

    }


    ngOnInit() {

    }

    openDialog() {
        this.dialogRef = this.dialog.open(ProductShareDialog, {
            data: {
                title: "Share Product",
                productId: this.productId
            }
        });
        this.dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result}`);
        });
    }

    openEmailShareDialog() {
        this.dialogRef = this.dialog.open(VendorEmailShareDialog, {
            data: {
                title: "Share Product",
                product: this.product
            }
        });
        this.dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result}`);
        });
    }

    public share(socialShare: string) {
        if (isPlatformBrowser(this.platformId)) {
            const url = `https://premierstreets.com/products/productdetails/${this.product.productSlug}`;
            switch (socialShare) {
                case 'fb':
                    window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`);
                    break;
                case 'tw':
                    window.open(`https://twitter.com/home?status=${url}`);
                    break;
                case 'in':
                    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${url}`);
                    break;
                case 'tr':
                    window.open(`https://www.tumblr.com/widgets/share/tool?canonicalUrl=${url}`);
                    break;
                case 'dg':
                    window.open(`http://digg.com/submit?url=${url}`);
                    break;
                case 'rd':
                    window.open(`https://reddit.com/submit?url=${url}`);
                    break;
                case 'pn':
                    window.open(`https://www.pinterest.com/pin/find/?url=${url}`);
                    break;
                default:
                    break;
            }
        }
    }

    ngOnDestroy() {

    }
}