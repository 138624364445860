import { Component, Inject, OnInit, } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'welcome-modal',
    templateUrl: './welcome-modal.component.html',
    styleUrls: ['./welcome-modal.component.scss']
})
export class WelcomeModalComponent implements OnInit {

    constructor(
        private dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private sanitizer: DomSanitizer
    ) {

    }
    ngOnInit() {
    }
    save() {
        this.dialogRef.close();
    }
    close() {
        this.dialogRef.close();
    }
}