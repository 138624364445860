import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PostService } from "../../../../../core/posts/post.service";

@Component({
    selector: 'product-share-popup',
    templateUrl: 'product-share-popup.component.html',
})
export class ProductShareDialog implements OnInit {
    public postForm: FormGroup;
    public submitting: boolean = false;
    constructor(
        private postService: PostService,
        private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<ProductShareDialog>
    ) {

    }

    ngOnInit() {
        this.postForm = this.formBuilder.group({
            comment: ['', Validators.required]
        });
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    submit(){
        if(!this.postForm.valid){
            this.validateAllFormFields(this.postForm);
            return;
        }
        this.submitting = true;
        this.postService.createProductPost({
            comment: this.postForm.controls['comment'].value,
            productId: this.data.productId
        }).toPromise().then((response) => {
            this.dialogRef.close();
        }, (err) => {

        })
    }
    

}