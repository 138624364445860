import { DatePipe } from "@angular/common";
import { Component, Inject, OnDestroy, OnInit, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import * as moment from "moment";
import { CommonService } from "../../../core/common/common.service";
import { ListsService } from "../../../core/lists/lists.service";
import { fabric } from 'fabric';
import { ConfigService } from "../../../core/service/config.service";

@Component({
    selector: 'product-preview-modal',
    templateUrl: './product-preview-modal.component.html',
    styleUrls: ['./product-preview-modal.component.scss']
})
export class ProductPrevieModalComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('fileInput', { static: false }) fileInputRef: ElementRef<HTMLInputElement>;

    public submitting = false;
    public selectedDate;
    public minDate = { year: moment().year(), month: moment().month() + 1, day: moment().date() };

    public slots = [];
    public employeeShow = false;
    public vendorCustomerLists = [];
    public assignedTo = 0;
    public dateValue: any = { year: moment().year(), month: moment().month(), day: moment().date() };;
    public totalCount = 0;
    public noBooking = false;
    public subscriptions = [];
    isLoading: boolean;
    public canvas: any;
    canvasWidth = 700;
    canvasHeight = 350;
    public previewUploadProgress = 0;
    public uploadedImage = '';
    public isImageUploadLabel = 'Upload Picture';


    public canvasPlotedImage;

    public bookingForm = this.fb.group({
        bookingType: ['vendor', Validators.required],
        selectedDate: ['', Validators.required],
        slotId: ['', Validators.required],
        customerId: [''],
    })

    constructor(
        private dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private datePipe: DatePipe,
        public toaster: MatSnackBar,
        public route: Router,
        public listService: ListsService,
        public configService: ConfigService,
        public commonService: CommonService
    ) {

    }

    public hideLoader() {
        this.isLoading = false;
    }
    public loading() {
        this.isLoading = true;
    }

    ngOnInit() {
        console.log("isImageUpload data");
        console.log(this.isImageUploadLabel);
        console.log("data of produyct");
        console.log(this.data);
        console.log(" **** this.minDate ", this.minDate);
    }

    // save() {

    //     if (!this.bookingForm.valid) {
    //         this.bookingForm.markAllAsTouched();
    //         return;
    //     }
    //     this.loading();

    //     let selectedDate;
    //     const date = this.bookingForm.value.selectedDate;
    //     const fDate = new Date(date.year, date.month - 1, date.day);
    //     selectedDate = this.datePipe.transform(fDate, 'yyyy/MM/dd');
    //     this.submitting = true;
    //     let params = {
    //         bookingDate: selectedDate,
    //         slotId: this.bookingForm.value.slotId,
    //         vendorId: this.data.vendorId,
    //         assignedTo: this.assignedTo,
    //         productId: this.data.productId
    //     }
    //     this.commonService.createBooking(params).toPromise().then((response) => {
    //         this.hideLoader();
    //         this.dialogRef.close(true);
    //         this.route.navigate(['/booking/payment', response.data.id])
    //     }).catch(err => {
    //     });
    // }




    close() {
        this.dialogRef.close(false);
    }

    saveModifyImage() {
        const imagePath = this.canvas.toDataURL({
            format: 'png',
            quality: 0.8
        });
        this.loading();
        const data = new FormData()
        const params = {
            slug: this.data.vendorPrefixId,
            data: imagePath,
            plotedImage: this.canvasPlotedImage
        }
        // data.append('file', imagePath);
        this.listService
            .uploadBlobData(params)
            .toPromise().then((upload) => {
                this.isLoading = false;
                console.log("upload path");
                console.log(upload);
                this.dialogRef.close(upload);
            }).catch((err) => {
                this.isLoading = false;
            });
    }

    async ngAfterViewInit() {
        this.canvas = new fabric.Canvas('canvas', {
            preserveObjectStack: true
        });

        this.canvas.on('object:moving', (e) => {
            console.log(" moving ", e);
        })

        this.setCanvasSize({
            height: this.canvasHeight,
            width: this.canvasWidth
        });
        const imageUrl = this.data.imageUrl;

        console.log(" *** imageUrl ", imageUrl);
        fabric.Image.fromURL(imageUrl, (img) => {
            var scale = Math.min(this.canvas.width / img.width, this.canvas.height / img.height);
            var w = img.width * scale;
            var h = img.height * scale;

            var left = this.canvas.width / 2 - w / 2;
            var top = this.canvas.height / 2 - h / 2;

            let imageData = img.set({
                left: left,
                top: top,
            });
            //  const scaleFactor = Math.min(this.canvas.width / img.width, this.canvas.height / img.height);
            img.scale(scale);

            imageData.path = '';
            imageData.hasBorders = true;
            imageData.hasControls = false;
            imageData.selectable = false;
            this.canvas.add(imageData)
            this.canvas.renderAll();

        }, {
            crossOrigin: "Anonymous",
        });
    }


    openFileInput() {
        this.fileInputRef.nativeElement.click();
    }
    //*** Resizing Canvas
    setCanvasSize(canvasSizeObject) {
        this.canvas.setWidth(canvasSizeObject.width);
        this.canvas.setHeight(canvasSizeObject.height);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(item => item.unsubscribe());
    }


    onFileChange(files: FileList | null): void {
        var file = files[0];
        var reader = new FileReader();
        reader.onload = (f) => {
            var data = f.target.result;

            this.canvasPlotedImage = data;

            console.log("data of file ");
            console.log(data);
            fabric.Image.fromURL(data, (img) => {
                const currentImage = this.canvas.getObjects()[1];

                var scale = Math.min(this.canvas.width / img.width, this.canvas.height / img.height);
                scale = scale / 4;
                var w = img.width * scale;
                var h = img.height * scale;

                var left = this.canvas.width / 2 - w / 2;
                var top = this.canvas.height / 2 - h / 2;

                let imageData = img.set({
                    left: left,
                    top: top,
                });

                img.scale(scale);

                imageData.path = '';
                imageData.hasBorders = true;
                imageData.hasControls = true;
                imageData.selectable = true;

                console.log("ploted image");
                console.log(img);
                if (currentImage) {
                    this.canvas.remove(currentImage);
                    this.canvas.add(img);
                } else {
                    this.isImageUploadLabel = 'Replace Picture';
                    this.canvas.add(imageData);
                }
                this.canvas.renderAll();
            }, {
                crossOrigin: "Anonymous",
            });
        };
        reader.readAsDataURL(file);
    }

    // replaceImageOnCanvas(newImageUrl: string) {
    //     fabric.Image.fromURL(newImageUrl, (newImage: fabric.Image) => {
    //         const currentImage = this.canvas.getObjects()[0]; // Assuming there is only one image on the canvas
    //         this.canvas.remove(currentImage);
    //         this.canvas.add(newImage);
    //     });
    // }
}