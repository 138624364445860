import { gifToSprites } from './gifToSprite';
import { fabric } from 'fabric';

const [PLAY, PAUSE, STOP, COMPLETED] = [0, 1, 2, 3];

export async function fabricGif(
    gif: string | File,
    maxWidth?: number,
    maxHeight?: number
): Promise<{ image: fabric.Image }> {
    const { framesPerSprite, sprites, frames, frameWidth, frameHeight, totalFrames } =
        await gifToSprites(gif, maxWidth, maxHeight);

    const frameCanvas = document.createElement('canvas');
    frameCanvas.width = frameWidth;
    frameCanvas.height = frameHeight;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const frameCtx = frameCanvas.getContext('2d')!;

    frameCtx.drawImage(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        sprites[0]!,
        0,
        0,
        frameWidth,
        frameHeight
    );

    return new Promise((resolve) => {
        fabric.Image.fromURL(frameCanvas.toDataURL(), (image) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const firstFrame = frames[0]!;
            let framesIndex = 0;
            let start = performance.now();
            let status: number;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            let accumulatedDelay = firstFrame.delay;

            image.width = frameWidth;
            image.height = frameHeight;
            image._render = function (ctx) {
                // console.log(" *** inside pause and completed ", status);
                if ((status === COMPLETED)) {
                    console.log(" *** inside pause and completed ");
                    ctx.drawImage(
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        sprites[sprites.length - 1]!,
                        frameWidth * ((sprites.length - 1) % framesPerSprite),
                        0,
                        frameWidth,
                        frameHeight,
                        -frameWidth / 2,
                        -frameHeight / 2,
                        frameWidth,
                        frameHeight
                    );
                    return;
                }

                if (((status === PAUSE))) {

                    ctx.drawImage(
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        sprites[0],
                        frameWidth * (0 % framesPerSprite),
                        0,
                        frameWidth,
                        frameHeight,
                        -frameWidth / 2,
                        -frameHeight / 2,
                        frameWidth,
                        frameHeight
                    );
                    // } else {
                    //     ctx.drawImage(
                    //         // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    //         sprites[sprites.length - 1],
                    //         frameWidth * ((sprites.length - 1) % framesPerSprite),
                    //         0,
                    //         frameWidth,
                    //         frameHeight,
                    //         -frameWidth / 2,
                    //         -frameHeight / 2,
                    //         frameWidth,
                    //         frameHeight
                    //     );
                    // }

                    return;
                };
                const now = performance.now();
                const delta = now - start;
                if (delta > accumulatedDelay) {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    accumulatedDelay += frames[framesIndex]!.delay;
                    framesIndex++;
                }
                if (framesIndex === (totalFrames - 1)) {
                    status = COMPLETED;
                }
                if (framesIndex === (totalFrames) || status === STOP) {
                    framesIndex = 0;
                    start = now;
                    accumulatedDelay = firstFrame.delay;
                }

                const spriteIndex = Math.floor(framesIndex / framesPerSprite);
                ctx.drawImage(
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    sprites[spriteIndex]!,
                    frameWidth * (framesIndex % framesPerSprite),
                    0,
                    frameWidth,
                    frameHeight,
                    -frameWidth / 2,
                    -frameHeight / 2,
                    frameWidth,
                    frameHeight
                );
            };

            const methods = {
                play: () => {
                    status = PLAY;
                    image.dirty = true;
                },
                pause: () => {
                    status = PAUSE;
                    image.dirty = false;
                },
                completed: () => {
                    status = COMPLETED;
                    image.dirty = false;
                },
                stop: () => {
                    status = STOP;
                    image.dirty = false;
                },
                isCompleted: () => {
                    return (framesIndex === (frames.length - 1));
                },
                getStatus: () => ['Playing', 'Paused', 'Stopped', 'Completed'][status]
            };

            //  methods.play();

            resolve({
                ...methods,
                image
            });
        });
    });
}