import { DatePipe } from "@angular/common";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import * as moment from "moment";
import { CommonService } from "../../../core/common/common.service";
import { ListsService } from "../../../core/lists/lists.service";

@Component({
    selector: 'booking-modal',
    templateUrl: './booking-modal.component.html',
    styleUrls: ['./booking-modal.component.scss']
})
export class BookingModalComponent implements OnInit, OnDestroy {

    public submitting = false;
    public selectedDate;
    public minDate = { year: moment().year(), month: moment().month() + 1, day: moment().date() };

    public slots = [];
    public employeeShow = false;
    public vendorCustomerLists = [];
    public assignedTo = 0;
    public dateValue: any = { year: moment().year(), month: moment().month(), day: moment().date() };;
    public totalCount = 0;
    public noBooking = false;
    public subscriptions = [];
    isLoading: boolean;

    public bookingForm = this.fb.group({
        bookingType: ['vendor', Validators.required],
        selectedDate: ['', Validators.required],
        slotId: ['', Validators.required],
        customerId: [''],
    })

    constructor(
        private dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private datePipe: DatePipe,
        public toaster: MatSnackBar,
        public route: Router,
        public listService: ListsService,
        public commonService: CommonService
    ) {
        this.subscriptions.push(this.bookingForm.controls['bookingType'].valueChanges.subscribe((value) => {
            if (value === 'vendor') {
                this.bookingForm.controls['customerId'].clearValidators();
                this.bookingForm.controls['customerId'].updateValueAndValidity();
            } else {
                this.bookingForm.controls['customerId'].setValidators([Validators.required]);
                this.bookingForm.controls['customerId'].updateValueAndValidity();
            }
        }));

    }

    public hideLoader() {
        this.isLoading = false;
    }
    public loading() {
        this.isLoading = true;
    }

    ngOnInit() {
        this.loading();
        console.log(" **** this.minDate ", this.minDate);
        this.getAvailableSlots(this.minDate);

        this.listService.getVendorCustomer(this.data.vendorId, this.data.productId).toPromise().then((responseData) => {
            this.vendorCustomerLists = responseData.data.filter(data => {
                if (data.customer?.commision != 0) {
                    return true;
                } else {
                    return false;
                }
            });
        });
    }

    getAvailableSlots(date: any) {
        this.noBooking = false;
        this.loading();
        this.dateValue = date;

        this.bookingForm.controls['slotId'].setValue('');
        // this.slots = [];
        const fDate = new Date(date.year, date.month - 1, date.day);
        let bookingDate = this.datePipe.transform(fDate, 'yyyy-MM-dd');
        if (this.assignedTo) {
            this.listService.getAvailableSlots({ vendorAdminId: this.assignedTo, bookingDate: bookingDate }).toPromise().then((response) => {
                this.slots = response.data;
                this.totalCount = response.data.length;
                this.noBooking = response.data.length ? false : true
                this.hideLoader();
            });
        } else {
            this.listService.getAvailableSlots({ vendorId: this.data.vendorId, bookingDate: bookingDate }).toPromise().then((response) => {
                this.slots = response.data;
                this.totalCount = response.data.length;
                this.noBooking = response.data.length ? false : true
                this.hideLoader();
            });
        }
    }

    public isGone(slot) {
        return moment(slot.slot.startTime, 'hh:mm a').set({ date: this.dateValue.day, month: this.dateValue.month }).isBefore(moment());
    }

    save() {

        if (!this.bookingForm.valid) {
            this.bookingForm.markAllAsTouched();
            return;
        }
        this.loading();

        let selectedDate;
        const date = this.bookingForm.value.selectedDate;
        const fDate = new Date(date.year, date.month - 1, date.day);
        selectedDate = this.datePipe.transform(fDate, 'yyyy/MM/dd');
        this.submitting = true;
        let params = {
            bookingDate: selectedDate,
            slotId: this.bookingForm.value.slotId,
            vendorId: this.data.vendorId,
            assignedTo: this.assignedTo,
            productId: this.data.productId
        }
        this.commonService.createBooking(params).toPromise().then((response) => {
            this.hideLoader();
            this.dialogRef.close(true);
            this.route.navigate(['/booking/payment', response.data.id])
        }).catch(err => {
        });
    }

    close() {
        this.dialogRef.close(false);
    }



    showUsers(val) {
        this.noBooking = false;
        if (val == 0) {
            this.assignedTo = 0;
            this.bookingForm.controls['customerId'].setValue('');
            this.employeeShow = false;
            if (this.dateValue) {
                this.loading();
                this.bookingForm.controls['slotId'].setValue('');

                const fDate = new Date(this.dateValue.year, this.dateValue.month - 1, this.dateValue.day);
                let bookingDate = this.datePipe.transform(fDate, 'yyyy-MM-dd');
                this.listService.getAvailableSlots({ vendorId: this.data.vendorId, bookingDate: bookingDate }).toPromise().then((response) => {
                    this.slots = response.data;
                    this.totalCount = response.data.length;
                    this.noBooking = response.data.length ? false : true
                    this.hideLoader();
                });
            }
        } else {
            this.employeeShow = true;

        }
    }


    // calls getProducts for filter
    public onChangeCategory(params) {
        this.noBooking = false;
        this.assignedTo = params.target.value;
        this.bookingForm.controls['slotId'].setValue('');
        if (this.dateValue) {
            this.loading();
            const fDate = new Date(this.dateValue.year, this.dateValue.month - 1, this.dateValue.day);
            let bookingDate = this.datePipe.transform(fDate, 'yyyy-MM-dd');
            this.listService.getAvailableSlots({ vendorAdminId: this.assignedTo, bookingDate: bookingDate }).toPromise().then((response) => {
                this.slots = response.data;
                this.totalCount = response.data.length;
                this.noBooking = response.data.length ? false : true
                this.hideLoader();
            });
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(item => item.unsubscribe());
    }



}