import { Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectorRef, Input, Inject, PLATFORM_ID, Output, EventEmitter, OnChanges, SimpleChanges, Renderer2, ElementRef, AfterViewChecked } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { isPlatformBrowser, Location } from '@angular/common';
import { fabric } from 'fabric';
import FontFaceObserver from 'fontfaceobserver';
import { TemplateService } from '../../../core/template/template.service';
import { ConfigService } from '../../../core/service/config.service';
import * as _ from 'lodash';
import { fabricGif } from "./fabricGif";

let logo1: any = {},
    logo2,
    logo3: any = {},
    logo5: any = {},
    bike: any = {},
    text: any = {},
    floorImage: any = {},
    shopFront: any = {},
    flag: any = {},
    signBoard: any = {},
    frameImage: any = {},
    BorderImage: any = {},
    streetlamp: any = {},
    logo4: any = {},
    aboard: any = {},
    oldZoom = 1,
    units = 20,
    scale = 1.3,
    canvasWidth = 1350,
    canvasHeight = 660;



@Component({
    selector: 'template-builder',
    templateUrl: './template-builder.component.html',
    styleUrls: ['./template-builder.component.scss']
})
export class DynamicTemplateBuilderComponent implements OnInit, AfterViewInit, OnDestroy {

    public limit = 10;
    public offset = 0;
    public currentPage = 1;
    public currencySymbol: any = JSON.parse(localStorage.getItem('vendor-settings'));
    public config: SwiperConfigInterface = {};
    private subscriptions: Array<Subscription> = [];
    public canvas: any;
    public zoom = 2;
    public zIndex = 0;
    public text = null;
    public enableAnimation = 0;
    public components = [];
    public glowingPlants = [];
    public doorComponents = [];
    public wallTexture = null;
    public textInterval = null;
    public planInterval = null;
    public floorTexture = null;
    public sofa = null;
    public computer = null;
    public table = null;
    public variants = [];
    public canvasObjects = [];
    public id: number = 0;
    public templateData: any;
    public closingDoor;
    public openingDoor;
    public animating = false;
    public rendered = false;
    public templateLoading = false;
    @Input('vendorId') vendorId: number;
    @Input('index') index: number;
    @Input('currentIndex') currentIndex: number;
    @Input('totalItems') totalItems: number;
    @Input() events: Observable<void>;
    @Output() templateLoaded = new EventEmitter();

    public frontImage11 = "./assets/humble/front/large/Black-door-open.gif";
    public frontImage12 = "./assets/humble/front/large/Black-door-close.gif";



    public logoImage = "";
    public bikeImage = "./assets/humble/extras/cycle.png";
    public aboardImage = "./assets/humble/extras/board.png";


    public shopName = '';
    public fontColor = '#ffffff';
    public fontStyle = 'Roboto';
    public uploadUrl = '';
    public render = null;

    public innerTemplate: any;

    constructor(
        public settingService: TemplateService,
        public configService: ConfigService,
        public router: Router,
        public route: ActivatedRoute,
        public cd: ChangeDetectorRef,
        private renderer: Renderer2,
        private elem: ElementRef,
        @Inject(PLATFORM_ID) private platformId: Object,
        private location: Location) { }

    ngOnInit() {
        this.uploadUrl = this.configService.getUploadUrl();
        if (isPlatformBrowser(this.platformId)) {
            scale = (window.innerWidth / 1350) * 0.903;

            if (window.innerWidth <= 730) {
                scale = (window.innerWidth / 1000) * 1;
            }

            if (window.innerWidth <= 730) {
                scale = (window.innerWidth / 1000) * 1;
                if (this.currentIndex === this.index) {
                    if (!this.templateLoading) {
                        this.getTemplate();
                    }
                }
            } else {

                let down = this.currentIndex - 1;
                let up = this.currentIndex + 1;
                if ((down <= this.index) && (this.index <= up)) {
                    if (!this.templateLoading) {
                        this.getTemplate();
                    }
                }

                if ((this.index + 1) === this.totalItems) {
                    if (!this.templateLoading) {
                        this.getTemplate();
                    }
                }
            }

            this.subscriptions.push(this.events.subscribe((e: any) => {

                this.uploadUrl = this.configService.getUploadUrl();

                if (window.innerWidth <= 730) {


                } else {
                    let down = e - 1;
                    let up = e + 1;
                    if ((down <= this.index) && (this.index <= up)) {
                        if (!this.templateLoading) {
                            this.getTemplate();
                        }
                    }
                }

                if (this.index === e) {
                    if (this.render) {
                        this.setupTextInterval();
                        this.planInterval = setInterval(() => {
                            if (this.glowingPlants.length) {
                                for (let plant of this.glowingPlants) {
                                    if (plant.getStatus() === 'Completed') {
                                        plant.play();
                                    }
                                }
                            }
                            fabric.util.requestAnimFrame(this.render);
                        }, 100);
                    } else if (!this.templateLoading) {
                        this.getTemplate();
                    }
                } else {
                    if (this.planInterval) {
                        clearInterval(this.planInterval)
                    }

                    if (this.textInterval) {
                        clearInterval(this.textInterval);
                    }
                }
            }));

        }


    }

    // ngOnChanges(changes: SimpleChanges): void {
    //     console.log(" ****  changes ", changes);
    // }

    async ngAfterViewInit() {

        if (isPlatformBrowser(this.platformId)) {

            var defaultOnTouchStartHandler = fabric.Canvas.prototype._onTouchStart;
            fabric.util.object.extend(fabric.Canvas.prototype, {
                _onTouchStart: function (e) {
                    var target = this.findTarget(e);
                    // if allowTouchScrolling is enabled, no object was at the
                    // the touch position and we're not in drawing mode, then 
                    // let the event skip the fabricjs canvas and do default
                    // behavior

                    console.log(" **** _onTouchStart ", this.allowTouchScrolling, target, this.isDrawingMode);

                    if (this.allowTouchScrolling && (target && target.type !== 'door') && !this.isDrawingMode) {
                        // returning here should allow the event to propagate and be handled
                        // normally by the browser
                        return;
                    }

                    // otherwise call the default behavior
                    defaultOnTouchStartHandler.call(this, e);
                }
            });

            this.canvas = new fabric.Canvas('canvas_' + this.vendorId, { selection: false, hoverCursor: "pointer", allowTouchScrolling: true });
            // this.canvas.on('object:moving', (e) => {
            //     console.log(" moving ", e);
            // })
            this.setCanvasSize({
                height: canvasHeight * scale,
                width: canvasWidth * scale
            });

            // this.canvas.on('mouse:move', (event) => {
            //     event.e.preventDefault();
            //     return false;
            // });

            this.canvas.on('touch:drag', () => {
                console.log(" *** touch drag ")
                return false;
            })


            this.canvas.on('touchend', () => {
                console.log(" *** touchend ")
                return false;
            })

        }
    }

    goToRoute(route) {
        this.router.navigate(route).then(() => { window.location.reload(); })
    }

    //*** Resizing Canvas
    setCanvasSize(canvasSizeObject) {
        this.canvas.setWidth(canvasSizeObject.width);
        this.canvas.setHeight(canvasSizeObject.height);
    }

    //*** Removing Element
    removeElement(element) {
        this.canvas.remove(element);
    }

    uploadLogo(e) {
        var reader = new FileReader();
        reader.onload = (event) => {
            let imgObj: any = new Image();
            imgObj.src = event.target.result;
            imgObj.onload = () => {
                this.changeLogoImage(imgObj.src, true);

            };
        }
        reader.readAsDataURL(e.target.files[0]);
        this.canvas.renderAll();
    }




    changeBorderImage(sourceImage, change) {
        if (change == true) {
            this.removeElement(BorderImage);
        }
        fabric.Image.fromURL(sourceImage, (img) => {
            BorderImage = img.set({
                left: BorderImage.left || 133,
                top: BorderImage.top || 41
            }).scale(0.632);
            this.canvas.insertAt(BorderImage, 5);
            BorderImage.hasBorders = false;
            BorderImage.hasControls = false;
            BorderImage.selectable = false;
        });
    }

    //*** Setting shop top shed image
    changeBikeImage(bikeImage, change = false) {
        if (change) {
            this.removeElement(bike);
            bike = {};
        }
        fabric.Image.fromURL(bikeImage, (img) => {
            bike = img.set({
                left: bike.left || 630,
                top: bike.top || 390,
                visible: bike.visible === false ? false : true
            }).scale(0.520834);
            this.canvas.insertAt(bike, 2);
            bike.url = bikeImage;
            bike.hasBorders = false;
            bike.hasControls = false;
            bike.selectable = true;
            this.canvas.renderAll();
        });
    }


    //*** Setting shop top shed image
    setFloorImage(floorImageSrc) {
        fabric.Image.fromURL(floorImageSrc, (img) => {
            floorImage = img.set({
                left: floorImage.left || 0,
                top: floorImage.top || 625
            }).scale(0.640);
            this.canvas.insertAt(floorImage, 0);
            floorImage.url = floorImageSrc;
            floorImage.hasBorders = false;
            floorImage.hasControls = false;
            floorImage.selectable = false;
            this.canvas.renderAll();
        });
    }


    changeShopFrontImage(sourceImage, change) {
        if (change == true) {
            this.removeElement(shopFront);
            shopFront = {};
        }
        fabric.Image.fromURL(sourceImage, (img) => {
            shopFront = img.set({
                left: shopFront.left || -10,
                top: shopFront.top || 0
            }).scale(0.845);
            this.canvas.insertAt(shopFront, 2);
            this.canvas.sendToBack(shopFront);
            shopFront.url = sourceImage;
            shopFront.hasBorders = false;
            shopFront.hasControls = false;
            shopFront.selectable = false;
        });
    }

    changeLogoImage(sourceImage, change) {
        if (change == true) {
            this.removeElement(logo1);
        }

        if (change == true) {
            this.removeElement(logo3);
        }
        fabric.Image.fromURL(sourceImage, (img) => {
            logo3 = img.set({
                left: logo3.left || 230,
                top: logo3.top || 47
            }).scale(0.18);
            this.canvas.insertAt(logo3, 10);
            logo3.url = sourceImage;
            logo3.hasBorders = false;
            logo3.hasControls = false;
            logo3.selectable = false;
        });
        if (change == true) {
            this.removeElement(logo5);
        }

    }

    loadAndUse(font) {
        var myfont = new FontFaceObserver(font)
        myfont.load().then(() => {
            this.canvas.getActiveObject().set("fontFamily", font);
            this.canvas.discardActiveObject();
            this.canvas.requestRenderAll();
        }).catch(function (e) {
            console.log(e)
        });
    }

    addShopName() {
        if (this.shopName.length > 0) {
            this.removeElement(text);
            text = new fabric.Text(this.shopName, {
                width: 370,
                top: text.top || 45,
                left: text.left || 440,
                fontSize: 22,
                textAlign: 'center',
                fixedWidth: 370,
                fill: this.fontColor
            });
            this.canvas.on('text:changed', function (opt) {
                var text = opt.target;
                if (text.width > text.fixedWidth) {
                    text.fontSize *= text.fixedWidth / (text.width + 1);
                    text.width = text.fixedWidth;
                }
            });
            text.type = 'text';
            this.canvas.insertAt(text, 7);
            this.canvas.setActiveObject(text);
            if (this.fontStyle) {
                this.loadAndUse(this.fontStyle);
            }

            text.title = this.shopName;
            text.hasBorders = false;
            text.hasControls = false;
            text.selectable = false;
            text.fillStyle = '#FFFFFF';

            // let i = 1;
            // setInterval(() => {
            //     console.log(" ** intervanl running ");
            //     text.set({
            //         shadow: new fabric.Shadow({
            //             color: "#fff",
            //             blur: (i % 2 === 0) ? 20 : i,
            //             offsetX: 0,
            //             offsetY: 0
            //         })
            //     });
            //     if (i === 10) {
            //         i = 1;
            //     } else {
            //         i = i + 1;
            //     }

            //     this.canvas.renderAll();
            // }, 500);


        } else {

        }
    }


    changeRightBoard(sourceImage, change) {
        if (change == true) {
            this.removeElement(frameImage);
        }
        fabric.Image.fromURL(sourceImage, (img) => {
            frameImage = img.set({
                left: frameImage.left || 527,
                top: frameImage.top || 347
            }).scale(0.254);
            this.canvas.insertAt(frameImage, 5);
            frameImage.url = sourceImage;
            frameImage.hasBorders = false;
            frameImage.hasControls = false;
            frameImage.selectable = false;
        });
    }

    changeSignBoardImage(sourceImage, change) {
        if (change == true) {
            this.removeElement(signBoard);
        }
        fabric.Image.fromURL(sourceImage, (img) => {
            signBoard = img.set({
                left: signBoard.left || 30,
                top: signBoard.top || 60
            }).scale(0.8);
            this.canvas.insertAt(signBoard, 7);
            signBoard.url = sourceImage;
            signBoard.hasBorders = false;
            signBoard.hasControls = false;
            signBoard.selectable = false;
            //changeLogoImage(logoImage, true);
        });
    }

    changeAboardLogo(sourceImage, change) {
        if (change) {
            this.removeElement(logo4);
        }
        fabric.Image.fromURL(sourceImage, (img) => {
            logo4 = img.set({
                left: logo4.left || 193,
                top: logo4.top || 505
            }).scale(0.14);
            logo4.set('skewX', 15).set('skewY', -12).setCoords();
            this.canvas.insertAt(logo4, 15);
            logo4.url = sourceImage;
            logo4.hasBorders = false;
            logo4.hasControls = false;
            logo4.selectable = false;
        });
    }

    changeaboardImage(sourceImage, change = false) {
        if (change) {
            this.removeElement(aboard);
            aboard = {};
        }
        fabric.Image.fromURL(sourceImage, (img) => {
            aboard = img.set({
                left: aboard.left || -120,
                top: aboard.top || 390,
                visible: aboard.visible === false ? false : true
            }).scale(0.62);
            this.canvas.insertAt(aboard, 13);
            aboard.url = sourceImage;
            aboard.hasBorders = false;
            aboard.hasControls = false;
            aboard.selectable = false;

        });
    }


    //*** Common function for boolean properties
    observeBoolean(element, property, target) {
        target[property] = element.checked;
    }

    //*** Common function for changing image
    observeImage(imagePath, functionName) {
        if (functionName == 'shopFront') {
            this.changeShopFrontImage(imagePath, true);
        } else if (functionName == "sign-board") {
            this.changeSignBoardImage(imagePath, true);
        }
        this.canvas.renderAll();
    }

    //*** Setting shop top image
    setFlag(flagImage) {
        fabric.Image.fromURL(flagImage, (img) => {
            flag = img.set({
                left: flag.left || 410,
                top: flag.top || 88
            }).scale(0.52);
            this.canvas.insertAt(flag, 9);
            flag.url = flagImage;
            flag.hasBorders = false;
            flag.hasControls = false;
            flag.selectable = false;
            this.canvas.renderAll();
        });
    }


    toggleBike(event) {
        this.observeBoolean(event.target, 'visible', bike);
        this.canvas.renderAll();
    }

    cloneCanvas(oldCanvas) {
        //create a new canvas
        var newCanvas = document.createElement('canvas');
        newCanvas.width = oldCanvas.width;
        newCanvas.height = oldCanvas.height;
        let context = newCanvas.getContext('2d');
        let oldContext = oldCanvas.getContext('2d');
        let imageData = oldContext.getImageData(0, 0, 1368 * scale, 668 * scale);
        console.log(" ***imageData  ", imageData);
        //apply the old canvas to the new one
        context.putImageData(imageData, 0, 0);
        //return the new canvas
        return newCanvas;
    }

    toggle(event, componentData) {
        componentData.visible = !componentData.visible;
        const object = _.find(this.canvas._objects, (item) => item.objectId == componentData.id);
        this.observeBoolean(event.target, 'visible', object);
        this.canvas.renderAll();
    }

    toggleSteetLamp(event) {
        this.observeBoolean(event.target, 'visible', streetlamp);
        this.canvas.renderAll();
    }

    toggleBoard(event) {
        this.observeBoolean(event.target, 'visible', aboard);

        this.canvas.renderAll();
    }

    toggleShopLayout(event) {
        // this.setCanvasBackgroundImageUrl(event);
        this.canvas.renderAll();
    }

    toggleShopFascia(event) {
        this.observeImage(event, 'lamp');
        this.canvas.renderAll();
    }

    toggleFrontImage(event) {
        this.observeImage(event, 'shopFront');
        this.canvas.renderAll();
    }

    toggleImage(path, component) {
        const object = _.find(this.canvas._objects, (item) => item.objectId == component.id);
        let imageUrl = this.configService.getUploadUrl() + '/templates/' + path;
        object.path = path;
        object.setSrc(imageUrl, (img) => {
            this.canvas.renderAll();
        }, {
            crossOrigin: "Anonymous"
        });
    }

    toggleSignBoard(event) {
        console.log("  event ", event);
        this.observeImage(event, 'sign-board');
        this.canvas.renderAll();
    }

    changeZoom(zoom) {
        let targetLevel = zoom * 0.5;
        if (targetLevel == 1) {
            this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
        } else {
            if (oldZoom < targetLevel) {
                this.canvas.setZoom(this.canvas.getZoom() * targetLevel);
            } else {
                this.canvas.setZoom(this.canvas.getZoom() / targetLevel);
            }
        }
        units = units * targetLevel;
        oldZoom = targetLevel;
    }


    goRight() {
        var delta = new fabric.Point(units, 0);
        this.canvas.relativePan(delta);
    }
    goLeft() {
        var delta = new fabric.Point(-units, 0);
        this.canvas.relativePan(delta);
    }
    goUp() {
        var delta = new fabric.Point(0, -units);
        this.canvas.relativePan(delta);
    }
    goDown() {
        var delta = new fabric.Point(0, units);
        this.canvas.relativePan(delta);
    }


    async setUpDoorAnimations(item, itemOb) {

        // const worker = new Worker('./assets/worker');

        // worker.onmessage = ((event: any) => {
        //     const gif4: any = event.gif4

        //     this.closingDoor = gif4.image;

        //     const gif5: any = event.gif5;

        //     this.openingDoor = gif5.image;

        //     this.closingDoor.objectId = itemOb.id;
        //     this.openingDoor.objectId = itemOb.id;

        //     this.openingDoor.type = 'door';
        //     this.closingDoor.type = 'door';
        //     this.closingDoor.path = item.closeDoor;
        //     this.openingDoor.path = item.openDoor;

        //     this.render = () => {
        //         this.canvas.renderAll();
        //     }

        //     this.closingDoor.on('mouseover', () => {
        //         console.log('mouseover closing', this.animating);
        //         if (this.animating) {
        //             return;
        //         }
        //         this.animating = true;
        //         gif4.play();
        //         this.render = () => {
        //             this.canvas.renderAll();
        //             console.log(" *** gif4 ", gif4.getStatus());
        //             if (gif4.getStatus() !== 'Completed') {
        //                 // setTimeout(() => {
        //                 //     fabric.util.requestAnimFrame(render);
        //                 // }, 100);
        //             } else {
        //                 gif4.completed();
        //                 this.closingDoor.set({
        //                     visible: false
        //                 });
        //                 this.openingDoor.set({
        //                     visible: true
        //                 });
        //                 gif5.pause();
        //                 this.canvas.renderAll();
        //             }
        //         }
        //         // fabric.util.requestAnimFrame(render);
        //     });

        //     this.openingDoor.on('mouseover', () => {
        //         console.log('mouseover opening', this.animating);
        //         if (this.animating) {
        //             return;
        //         }
        //         this.animating = true;
        //         gif5.play();
        //         this.render = () => {
        //             this.canvas.renderAll();
        //             console.log(" *** gif5 ", gif5.getStatus(), this.animating);
        //             if (gif5.getStatus() !== 'Completed') {
        //                 // setTimeout(() => {
        //                 //     fabric.util.requestAnimFrame(render);
        //                 // }, 100);

        //             } else {
        //                 // fabric.util.requestAnimFrame(render);
        //                 gif5.completed();
        //                 gif4.pause();
        //                 this.closingDoor.set({
        //                     visible: true
        //                 });
        //                 this.openingDoor.set({
        //                     visible: false
        //                 });
        //                 this.canvas.renderAll();
        //                 //fabric.util.requestAnimFrame(render);
        //             }
        //         }
        //         // fabric.util.requestAnimFrame(render);
        //     });

        //     this.closingDoor.on('mouseout', () => {
        //         this.animating = false;
        //         console.log('mouseout closing', this.animating);
        //     });

        //     this.openingDoor.on('mouseout', () => {

        //         this.animating = false;
        //         console.log('mouseout opening', this.animating);
        //     });

        //     gif4.image.set({
        //         top: itemOb.top, left: itemOb.left, "scaleX": itemOb.scaleX,
        //         "scaleY": itemOb.scaleY,
        //         zIndex: itemOb.zIndex,
        //         visible: false,
        //         selectable: false
        //     });

        //     gif5.image.set({
        //         top: itemOb.top, left: itemOb.left, "scaleX": itemOb.scaleX,
        //         "scaleY": itemOb.scaleY,
        //         zIndex: itemOb.zIndex,
        //         visible: true,
        //         selectable: false
        //     });
        //     console.log(" *** this.openingDoor ", this.openingDoor);
        //     gif5.pause();

        //     // this.canvas.add(this.openingDoor);
        //     // this.canvas.add(this.closingDoor);
        //     this.openingDoor.doorType = 'open';
        //     this.closingDoor.doorType = 'close';
        //     this.canvas.add(this.openingDoor);
        //     this.canvas.add(this.closingDoor);

        //     //this.canvas.setActiveObject(this.openingDoor);
        //     //   this.zIndex = this.zIndex + 1;
        //     // this.canvas.bringForward(this.openingDoor);
        //     // this.canvas.bringForward(this.closingDoor);
        //     this.canvas.renderAll();
        //     this.cd.detectChanges();

        //     this.planInterval = setInterval(() => {
        //         if (this.glowingPlants.length) {
        //             for (let plant of this.glowingPlants) {
        //                 if (plant.getStatus() === 'Completed') {
        //                     plant.play();
        //                 }
        //             }
        //         }
        //         fabric.util.requestAnimFrame(this.render);
        //     }, 100);
        // });


        // worker.postMessage({
        //     closeDoor: item.closeDoor,
        //     openDoor: item.openDoor,
        //     uploadUrl: this.uploadUrl
        // });


        // console.log(" *** worker ", worker);

        const gif4: any = await fabricGif(
            this.uploadUrl + '/templates/' + item.closeDoor
        );

        this.closingDoor = gif4.image;

        const gif5: any = await fabricGif(
            this.uploadUrl + '/templates/' + item.openDoor
        );

        this.openingDoor = gif5.image;

        this.closingDoor.objectId = itemOb.id;
        this.openingDoor.objectId = itemOb.id;

        this.openingDoor.type = 'door';
        this.closingDoor.type = 'door';
        this.closingDoor.path = item.closeDoor;
        this.openingDoor.path = item.openDoor;

        this.render = () => {
            this.canvas.renderAll();
        }

        this.closingDoor.on('mouseover', () => {
            console.log('mouseover closing', this.animating);
            if (this.animating) {
                return;
            }
            this.animating = true;
            gif4.play();
            this.render = () => {
                this.canvas.renderAll();
                console.log(" *** gif4 ", gif4.getStatus());
                if (gif4.getStatus() !== 'Completed') {
                    // setTimeout(() => {
                    //     fabric.util.requestAnimFrame(render);
                    // }, 100);
                } else {
                    gif4.completed();
                    this.closingDoor.set({
                        visible: false
                    });
                    this.openingDoor.set({
                        visible: true
                    });
                    gif5.pause();
                    this.canvas.renderAll();
                }
            }
            // fabric.util.requestAnimFrame(render);
        });

        this.openingDoor.on('mouseover', () => {
            console.log('mouseover opening', this.animating);
            if (this.animating) {
                return;
            }
            this.animating = true;
            gif5.play();
            this.render = () => {
                this.canvas.renderAll();
                console.log(" *** gif5 ", gif5.getStatus(), this.animating);
                if (gif5.getStatus() !== 'Completed') {
                    // setTimeout(() => {
                    //     fabric.util.requestAnimFrame(render);
                    // }, 100);

                } else {
                    // fabric.util.requestAnimFrame(render);
                    gif5.completed();
                    gif4.pause();
                    this.closingDoor.set({
                        visible: true
                    });
                    this.openingDoor.set({
                        visible: false
                    });
                    this.canvas.renderAll();
                    //fabric.util.requestAnimFrame(render);
                }
            }
            // fabric.util.requestAnimFrame(render);
        });

        this.closingDoor.on('mouseout', () => {
            this.animating = false;
            console.log('mouseout closing', this.animating);
        });

        this.openingDoor.on('mouseout', () => {

            this.animating = false;
            console.log('mouseout opening', this.animating);
        });

        gif4.image.set({
            top: itemOb.top * scale, left: itemOb.left * scale, "scaleX": itemOb.scaleX * scale,
            "scaleY": itemOb.scaleY * scale,
            zIndex: itemOb.zIndex,
            visible: false,
            selectable: false
        });

        gif5.image.set({
            top: itemOb.top * scale, left: itemOb.left * scale, "scaleX": itemOb.scaleX * scale,
            "scaleY": itemOb.scaleY * scale,
            zIndex: itemOb.zIndex,
            visible: true,
            selectable: false
        });
        console.log(" *** this.openingDoor ", this.openingDoor);
        gif5.pause();

        // this.canvas.add(this.openingDoor);
        // this.canvas.add(this.closingDoor);
        this.openingDoor.doorType = 'open';
        this.closingDoor.doorType = 'close';
        this.canvas.add(this.openingDoor);
        this.canvas.add(this.closingDoor);

        //this.canvas.setActiveObject(this.openingDoor);
        //   this.zIndex = this.zIndex + 1;
        // this.canvas.bringForward(this.openingDoor);
        // this.canvas.bringForward(this.closingDoor);
        this.canvas.renderAll();
        this.cd.detectChanges();

        this.planInterval = setInterval(() => {
            if (this.glowingPlants.length) {
                for (let plant of this.glowingPlants) {
                    if (plant.getStatus() === 'Completed') {
                        plant.play();
                    }
                }
            }
            fabric.util.requestAnimFrame(this.render);
        }, 100);

    }

    async intializeCanvas(templateData) {

        this.canvas.clear();
        this.zIndex = 0;

        var result = Promise.resolve();

        const objects = templateData.canvasObjects;
        objects.forEach(async (item) => {
            let imageUrl = this.configService.getUploadUrl() + '/templates/' + item.path;

            if (item.type === 'door') {
                let doorComponents = this.doorComponents.find(dc => dc.id === item.id);
                await this.setUpDoorAnimations(doorComponents, item);
            } else {
                result = result.then(() => {
                    return new Promise((resolve, reject) => {
                        fabric.Image.fromURL(imageUrl, (img) => {
                            let imageData = img.set({
                                left: item.left * scale,
                                top: item.top * scale,
                                zIndex: item.zIndex,
                                scaleX: item.scaleX * scale,
                                scaleY: item.scaleY * scale,
                                visible: true
                            });
                            this.zIndex = this.zIndex + 1;
                            imageData.objectId = item.id;
                            this.canvas.add(imageData);
                            imageData.type = 'image';
                            imageData.path = item.path;
                            imageData.hasBorders = item.hasBorders;
                            imageData.hasControls = false //item.hasControls;
                            imageData.selectable = false //item.selectable;
                            this.canvas.renderAll();
                            this.cd.detectChanges();
                            resolve();
                        }, {
                            crossOrigin: "Anonymous",
                        });
                    })
                })
            }
        })


        // result.then(() => {
        //     this.canvas.add(this.openingDoor);
        // });

        // this.canvas.renderAll();
        // this.cd.detectChanges();

        setTimeout(() => {
            this.addShopName()
        }, 500);
        this.canvas.renderAll();
    }

    getTemplate() {
        this.templateLoading = true;
        this.settingService.geDynamictVendorTemplate({ vendorId: this.vendorId, templateId: 17 }).toPromise().then(async (response: any) => {

            if (response.templateData) {
                this.components = response.templateData.staticComponents;

                this.variants = response.templateData.variantComponents;

                this.canvasObjects = response.templateData.canvasObjects;

                this.templateData = response.templateData;

                this.doorComponents = response.templateData.doorComponents;

                this.innerTemplate = response.innerTemplate;

                this.wallTexture = response.templateData.wallTexture;

                this.floorTexture = response.templateData.floorTexture;

                this.sofa = response.templateData.sofa;

                this.computer = response.templateData.computer;

                this.table = response.templateData.table;

                if (response.data) {
                    this.templateLoaded.emit(response.data);
                    this.enableAnimation = response.data.enableAnimation;
                    let canvas_objects = response.data.canvas_objects;
                    if (this.wallTexture) {
                        this.wallTexture.selectedPath = response.data.wallTexture?.selectedPath || '';
                    }
                    if (this.floorTexture) {
                        this.floorTexture.selectedPath = response.data.floorTexture?.selectedPath || '';
                    }

                    if (this.sofa) {
                        this.sofa.visible = (response.data.sofa?.visible) ? true : false;
                    }

                    if (this.computer) {
                        this.computer.visible = (response.data.computer?.visible) ? true : false;
                    }
                    if (this.table) {
                        this.table.visible = (response.data.table?.visible) ? true : false;
                    }
                    //   canvas_objects = _.sortBy(canvas_objects, 'zIndex');
                    var result = Promise.resolve();

                    for (let item of canvas_objects) {
                        await this.addObjectToCanvas(item, response.data.canvas_objects, response);
                    }

                    if (this.index === 0) {
                        console.log(" *** inside fisrt ");
                        if (this.rendered) {
                            return;
                        }

                        this.rendered = true;
                        setTimeout(() => {
                            const element = document.querySelector('.swiper-slide:last-child .canvas-container');
                            element.innerHTML = '';
                            element.appendChild(this.canvas.toCanvasElement());
                        }, 1000)

                    }

                    if ((this.index + 1) === this.totalItems) {
                        console.log(" *** inside last ");
                        if (this.rendered) {
                            return;
                        }

                        this.rendered = true;
                        setTimeout(() => {
                            const element = document.querySelector('.swiper-slide:first-child .canvas-container');
                            element.innerHTML = '';
                            element.appendChild(this.canvas.toCanvasElement());
                        }, 1000)

                    }

                    // canvas_objects.forEach(async (item) => {
                    //     let imageUrl = this.configService.getUploadUrl() + '/templates/' + item.path;

                    //     if (item.type === 'door') {
                    //         let doorComponents = this.doorComponents.find(dc => dc.id === item.id);
                    //         await this.setUpDoorAnimations(doorComponents, item);
                    //     } else {
                    //         result = result.then(() => {
                    //             return new Promise(async (resolve, reject) => {
                    //                 if (item.type == 'text') {
                    //                     let text = item;
                    //                     this.shopName = text.title || '';
                    //                     this.fontColor = text.fill || '#ffffff';
                    //                     this.fontStyle = text.fontStyle || 'Roboto';

                    //                     text = new fabric.Text(this.shopName, {
                    //                         width: 370,
                    //                         top: text.top * scale || 45,
                    //                         left: text.left * scale || 440,
                    //                         fontSize: 22,
                    //                         textAlign: 'center',
                    //                         fixedWidth: 370,
                    //                         fill: this.fontColor,
                    //                         scaleX: item.scaleX * scale,
                    //                         scaleY: item.scaleY * scale

                    //                     });
                    //                     text.title = this.shopName;
                    //                     text.hasBorders = false;
                    //                     text.hasControls = false;
                    //                     text.selectable = false;
                    //                     text.fillStyle = '#FFFFFF';
                    //                     text.type = 'text';
                    //                     this.canvas.on('text:changed', function (opt) {
                    //                         var text = opt.target;
                    //                         if (text.width > text.fixedWidth) {
                    //                             text.fontSize *= text.fixedWidth * scale / (text.width * scale + 1);
                    //                             text.width = text.fixedWidth * scale;
                    //                         }
                    //                     });
                    //                     this.canvas.insertAt(text, this.zIndex);
                    //                     this.zIndex = this.zIndex + 1;
                    //                     if (this.fontStyle) {
                    //                         this.loadAndUse(this.fontStyle);
                    //                     }

                    //                     if (response.data.enableTextAnimations) {
                    //                         let i = 1;
                    //                         setInterval(() => {
                    //                             console.log(" ** intervanl running ");
                    //                             text.set({
                    //                                 shadow: new fabric.Shadow({
                    //                                     color: "#fff",
                    //                                     blur: (i % 2 === 0) ? 20 : i,
                    //                                     offsetX: 0,
                    //                                     offsetY: 0
                    //                                 })
                    //                             });
                    //                             if (i === 10) {
                    //                                 i = 1;
                    //                             } else {
                    //                                 i = i + 1;
                    //                             }

                    //                             this.canvas.renderAll();
                    //                         }, 500);
                    //                     }



                    //                     this.canvas.renderAll();
                    //                     this.cd.detectChanges();
                    //                     resolve();
                    //                 } else {
                    //                     const canvasObjects = response.templateData.canvasObjects;
                    //                     const findObject = _.find(canvasObjects, (item2 => item2.id == item.id));
                    //                     if (!findObject) {
                    //                         return resolve();
                    //                     }


                    //                     if (item.path.indexOf('.gif') !== -1) {

                    //                         let { image: imageData } = await fabricGif(
                    //                             this.uploadUrl + '/templates/' + item.path,
                    //                             200,
                    //                             200
                    //                         );
                    //                         imageData.type = 'gamla';
                    //                         console.log(" * *** this.uploadUrl + '/templates/' + item.path ", this.uploadUrl + '/templates/' + item.path, imageData);
                    //                         imageData.objectId = item.id;
                    //                         imageData.set({
                    //                             top: item.top, left: item.left, "scaleX": item.scaleX,
                    //                             "scaleY": item.scaleY,
                    //                             zIndex: item.zIndex,
                    //                             visible: true,
                    //                             selectable: true
                    //                         });

                    //                         imageData.path = item.path;
                    //                         imageData.hasBorders = item.hasBorders;
                    //                         imageData.hasControls = item.hasControls;
                    //                         imageData.selectable = item.selectable;

                    //                         this.canvas.add(imageData);
                    //                         //this.canvas.insertAt(imageData, 7);
                    //                         // this.canvas.bringToFront(imageData);
                    //                         const render = () => {
                    //                             setTimeout(() => {
                    //                                 fabric.util.requestAnimFrame(render);
                    //                             }, 500);
                    //                         }
                    //                         fabric.util.requestAnimFrame(render);
                    //                         return resolve();
                    //                     }

                    //                     fabric.Image.fromURL(imageUrl, (img) => {

                    //                         let imageData = img.set({
                    //                             left: item.left * scale,
                    //                             top: item.top * scale,
                    //                             zIndex: item.zIndex,
                    //                             scaleX: item.scaleX * scale,
                    //                             scaleY: item.scaleY * scale,
                    //                             visible: item.visible
                    //                         });
                    //                         this.zIndex = this.zIndex + 1;
                    //                         imageData.objectId = item.id;
                    //                         imageData.type = 'image';
                    //                         const isVariant = this.variants.findIndex(item2 => item2.id === item.id);

                    //                         console.log(isVariant, item);

                    //                         if (item.type === 'doorProduct') {

                    //                             this.canvas.add(imageData);
                    //                         } else if (item.type === 'windowProduct') {

                    //                             this.canvas.add(imageData);
                    //                         } else if (isVariant !== -1) {
                    //                             // if (item.selectable) {
                    //                             this.canvas.add(imageData);
                    //                             //   this.canvas.bringToFront(imageData);
                    //                             // } else {
                    //                             //     this.canvas.insertAt(imageData, 1);
                    //                             //     if (item.selectable) {
                    //                             //         this.canvas.bringToFront(imageData);
                    //                             //     }
                    //                             // }
                    //                         } else {
                    //                             this.canvas.add(imageData);
                    //                             // if (item.selectable) {
                    //                             //     this.canvas.bringToFront(imageData);
                    //                             // }
                    //                         }

                    //                         imageData.path = item.path;
                    //                         imageData.hasBorders = item.hasBorders;
                    //                         imageData.hasControls = false //item.hasControls;
                    //                         imageData.selectable = false //item.selectable;
                    //                         // console.log(" imageUrl ", imageUrl);
                    //                         // this.canvas.renderAll();
                    //                         // this.cd.detectChanges();
                    //                         resolve();
                    //                     }, {
                    //                         crossOrigin: "Anonymous",
                    //                     });
                    //                 }
                    //             });
                    //         })
                    //     }


                    // });
                }
            }


        }, (err: any) => [

        ])
    }



    public setupTextInterval() {
        if (this.text) {
            let i = 1;
            this.textInterval = setInterval(() => {

                this.text.set({
                    shadow: new fabric.Shadow({
                        color: "#fff",
                        blur: (i % 2 === 0) ? 20 : i,
                        offsetX: 0,
                        offsetY: 0
                    })
                });
                if (i === 10) {
                    i = 1;
                } else {
                    i = i + 1;
                }

                //  this.canvas.renderAll();
            }, 500);
        }

    }

    async addObjectToCanvas(item, canvasObjects, response) {

        if (item.type === 'door') {

            let doorComponents = this.doorComponents.find(dc => dc.id === item.id);
            console.log(" **** before door")
            await this.setUpDoorAnimations(doorComponents, item);
            console.log(" **** after door")
        } else {
            console.log(" **** after reset ");
            let imageUrl = this.configService.getUploadUrl() + '/templates/' + item.path;

            return new Promise(async (resolve, reject) => {
                if (item.type == 'text') {
                    let text = item;
                    this.shopName = text.title || '';
                    this.fontColor = text.fill || '#ffffff';
                    this.fontStyle = text.fontStyle || 'Roboto';

                    text = new fabric.Text(this.shopName, {
                        width: 370,
                        top: (text.top || 45) * scale,
                        left: (text.left || 440) * scale,
                        fontSize: 22,
                        textAlign: 'center',
                        fixedWidth: 370,
                        fill: this.fontColor,
                        scaleX: item.scaleX * scale,
                        scaleY: item.scaleY * scale

                    });
                    text.title = this.shopName;
                    text.hasBorders = false;
                    text.hasControls = false;
                    text.selectable = false;
                    text.fillStyle = this.fontColor;
                    text.type = 'text';
                    this.text = text;
                    this.canvas.on('text:changed', function (opt) {
                        var text = opt.target;
                        if (text.width > text.fixedWidth) {
                            text.fontSize *= text.fixedWidth / (text.width + 1);
                            text.width = text.fixedWidth;
                        }
                    });
                    this.canvas.insertAt(text, this.zIndex);
                    this.zIndex = this.zIndex + 1;
                    if (this.fontStyle) {
                        this.loadAndUse(this.fontStyle);
                    }

                    if (response.data.enableTextAnimations) {
                        this.setupTextInterval();
                    }

                    // this.canvas.renderAll();
                    // this.cd.detectChanges();
                    resolve(1);
                } else {
                    const findObject = _.find(canvasObjects, (item2 => item2.id == item.id));

                    if (!findObject) {
                        return resolve(1);
                    }

                    if (!item.path) {
                        return resolve(1);
                    }

                    if (item.path.indexOf('.gif') !== -1) {
                        let gif = null;
                        let { image: imageData } = gif = await fabricGif(
                            this.uploadUrl + '/templates/' + item.path,
                            200,
                            200
                        );
                        imageData.type = 'gamla';
                        console.log(" * *** this.uploadUrl + '/templates/' + item.path ", this.uploadUrl + '/templates/' + item.path, imageData);
                        imageData.objectId = item.id;
                        imageData.set({
                            top: item.top * scale, left: item.left * scale, "scaleX": item.scaleX * scale,
                            "scaleY": item.scaleY * scale,
                            zIndex: item.zIndex,
                            visible: true,
                            selectable: false
                        });

                        imageData.path = item.path;
                        imageData.hasBorders = item.hasBorders;
                        imageData.hasControls = false;// item.hasControls;
                        imageData.selectable = false;

                        if (response.data.enableAnimation) {
                            this.canvas.add(imageData);
                            this.glowingPlants.push(gif);
                            //this.canvas.insertAt(imageData, 7);
                            // this.canvas.bringToFront(imageData);
                            // const render = () => {
                            //     this.canvas.renderAll();
                            //     setTimeout(() => {
                            //         fabric.util.requestAnimFrame(render);
                            //     }, 500);
                            // }
                            // fabric.util.requestAnimFrame(render);
                        }


                        return resolve(1);
                    }

                    fabric.Image.fromURL(imageUrl, (img) => {
                        let imageData = img.set({
                            left: item.left * scale,
                            top: item.top * scale,
                            zIndex: item.zIndex,
                            scaleX: item.scaleX * scale,
                            scaleY: item.scaleY * scale,
                            visible: item.visible || true
                        });
                        this.zIndex = this.zIndex + 1;
                        imageData.objectId = item.id;
                        imageData.type = item.type || 'image';
                        const isVariant = this.variants.findIndex(item2 => item2.id === item.id);

                        console.log(isVariant, item);

                        if (item.type === 'doorProduct') {

                            this.canvas.add(imageData);
                            // this.canvas.insertAt(imageData, 0);
                        } else if (item.type === 'windowProduct') {

                            this.canvas.add(imageData);
                            //this.canvas.insertAt(imageData, 0);
                        } else if (isVariant !== -1) {
                            if (item.selectable) {
                                this.canvas.add(imageData);
                                // this.canvas.insertAt(imageData, 2);
                                //this.canvas.bringToFront(imageData);
                            } else {
                                this.canvas.add(imageData);
                                //this.canvas.insertAt(imageData, 1);
                                // if (this.doorObject) {
                                //     const object = this.canvas.getObjects().find(ob => ob.objectId === this.doorObject.id);
                                //     console.log(" **object ", object);
                                //     if (object && imageData.intersectsWithObject(object)) {
                                //         console.log(" *** intersection ", item);
                                //         this.canvas.sendBackwards(imageData, true);
                                //     }
                                // }

                                // this.canvas.pushToBack(imageData);
                            }
                        } else {
                            this.canvas.add(imageData);
                            //this.canvas.insertAt(imageData, 4);
                            // if (item.selectable) {
                            //     this.canvas.bringToFront(imageData);
                            // }
                        }
                        imageData.path = item.path;
                        imageData.hasBorders = item.hasBorders;
                        imageData.hasControls = false; // item.hasControls;
                        imageData.selectable = false //item.selectable;
                        // this.canvas.renderAll();
                        // this.cd.detectChanges();
                        resolve(1);
                    }, {
                        crossOrigin: "Anonymous",
                    });
                }
            });

        }

    }


    isChecked(componentData) {
        const object = _.find(this.canvas._objects, (item) => item.objectId == componentData.id);
        if (object) {
            return object.visible;
        }
        return false;
    }

    submit() {


    }


    ngOnDestroy() {
        this.canvas.dispose();
        this.subscriptions.forEach(each => each.unsubscribe());
        if (this.planInterval) {
            clearInterval(this.planInterval);
        }
        if (this.textInterval) {
            clearInterval(this.textInterval);
        }
    }
    goBack() {
        this.location.back();
    }
}
