import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {OverlayContainer} from '@angular/cdk/overlay';
@Injectable()
export class CustomOverlayContainer extends OverlayContainer {

    _createContainer(): void {
            const container = this._document.createElement('div');
            container.classList.add('cdk-overlay-container');
            this._document.getElementById('app').appendChild(container);
            this._containerElement = container;
    }
}
